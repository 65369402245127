
import React, { useState, useEffect, useRef, useReducer } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SelectField from "../../Report/SelectField";
import DatePicker from '../../Report/DatePicker'
import TimerPickerSelect from '../TimerPickerSelect'
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { subHours, addDays, subDays } from "date-fns";
import TextFieldSelectHourBefore from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";

import api from "../../../services/api";
import toastError from "../../../errors/toastError";


const reducer = (state, action) => {


  if (action.type === "LOAD_SCHEDULES") {
    const schedulesContact = action.payload;
    const newSchedules = [];

    schedulesContact.forEach((schedule) => {
      const scheduleIndex = state.findIndex((s) => s.id === schedule.id);
      if (scheduleIndex !== -1) {
        state[scheduleIndex] = schedule;
      } else {
        newSchedules.push(schedule);
      }
    });

    return [...state, ...newSchedules];
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;
    const scheduleIndex = state.findIndex((q) => q.id === scheduleId);
    if (scheduleIndex !== -1) {
      state.splice(scheduleIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};



const Item = (props) => {

  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};



const Modal = (props) => {

  // const [clientSchedules, dispatch] = useReducer(reducer, []);
  // const [selectedSchedule, setSelectedSchedule] = useState(null); 
  const [open, setOpen] = useState(true);
  const [scroll, /*setScroll*/] = useState('body');
  const [statusChatEndId, setStatusChatEnd] = useState(null)
  const [startDate, setDatePicker] = useState(new Date())
  const [timerPicker, setTimerPicker] = useState(new Date())
  const [textArea1, setTextArea1] = useState()
  const [schedulesContact, dispatch] = useReducer(reducer, []);

  const [currencyHourBefore, setCurrency] = useState(null);
  const [currenciesTimeBefore, setCurrenciesTimeBefore] = useState(null);

  const [checked, setChecked] = useState(false);


  const handleCancel = (event, reason) => {

    if (reason && reason === "backdropClick")
      return;

    setOpen(false);
  };


  useEffect(() => {

    (async () => {
      try {

        const { data } = await api.get("/tickets/" + props.ticketId);

        dispatch({ type: "LOAD_SCHEDULES", payload: data.schedulesContact });

      } catch (err) {
        toastError(err);
      }
    })();
  }, [props]);


  function formatedTimeHour(timer) {
    return `${timer.getHours().toString().padStart(2, '0')}:${timer.getMinutes().toString().padStart(2, '0')}`
  }

  function formatedFullCurrentDate() {
    let dateCurrent = new Date()
    let day = dateCurrent.getDate().toString().padStart(2, '0');
    let month = (dateCurrent.getMonth() + 1).toString().padStart(2, '0');
    let year = dateCurrent.getFullYear();
    return `${year}-${month}-${day}`;
  }




  // const handleDeleteSchedule = async (scheduleId) => {
  //   try {
  //     await api.delete(`/schedule/${scheduleId}`);
  //     toast.success(("Lembrete/Agendamento deletado com sucesso!")); 
  //     dispatch({ type: "DELETE_SCHEDULE", payload: scheduleId }); 
  //   } catch (err) {
  //     toastError(err);
  //   }
  //   setSelectedSchedule(null);
  // };

  // Get from child 2
  const datePickerValue = (data) => {
    
    setDatePicker(data)


  }

  // Get from child 3
  const timerPickerValue = (data) => {
    
    setTimerPicker(data)


  }

  const dateCurrentFormated = (dateF = null) => {

    let date = null

    if (dateF) {
      date = new Date(dateF)
    }
    else {
      date = new Date();
    }

    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();

    return `${year}-${month}-${day}`

  }

  const handleChatEnd = (event, reason) => {

    let dataSendServer = { 'statusChatEndId': statusChatEndId, 'farewellMessage':checked }

    if (reason && reason === "backdropClick")
      return;

    if (statusChatEndId === '2' || statusChatEndId === '3') {

      

      if (startDate.trim().length === 0) {

        alert('Selecione uma data atual ou futura!')

        return
      }

      else if (textArea1 && textArea1.trim().length < 5) {
        alert('Mensagem muito curta!')
        return
      }
      else if (!textArea1) {
        alert('Defina uma mensagem para enviar para o cliente!')
        return
      }
      else if (formatedFullCurrentDate() === startDate &&
        ((new Date(timerPicker).getHours() < new Date().getHours() && new Date(timerPicker).getMinutes() <= new Date().getMinutes()) ||
          (new Date(timerPicker).getHours() === new Date().getHours() && new Date(timerPicker).getMinutes() <= new Date().getMinutes()) ||
          (new Date(timerPicker).getHours() < new Date().getHours() && new Date(timerPicker).getMinutes() >= new Date().getMinutes()) ||
          (new Date(timerPicker).getHours() < new Date().getHours))) {
        alert('Horário menor ou igual horário atual!')
        return
      }
      else if ((new Date(timerPicker).getHours() > 20 && new Date(timerPicker).getMinutes() > 0) ||
        (new Date(timerPicker).getHours() < 6)) {
        alert('Horário comercial inválido!\n Selecione um horário de lembrete válido entre às 06:00 e 20:00')
        return
      }



      let dateSendMessage = startDate
      let timeBefore = formatedTimeHour(new Date(`${startDate} ${timerPicker.getHours()}:${timerPicker.getMinutes()}:00`))

      if (statusChatEndId === '3') {

        if (!currencyHourBefore) {
          alert('Para agendamentos do dia corrente, essa funcionalidade atende a agendeamentos com no mínimo 2 horas adiantado a partir da hora atual!')
          return
        }

        timeBefore = currencyHourBefore

        let sendMessageDayBefore = currenciesTimeBefore.filter(i => i.label.indexOf('24 HORAS ANTES DO HORÁRIO DO AGENDAMENTO') >= 0);

        if (sendMessageDayBefore.length > 0 && timeBefore === formatedTimeHour(timerPicker)) {
          
          

          dateSendMessage = dateCurrentFormated(new Date(subDays(new Date(startDate + ' ' + formatedTimeHour(new Date(`${startDate} ${timerPicker.getHours()}:${timerPicker.getMinutes()}:00`))), 1)))
        }


        

      } else if (statusChatEndId === '2') {

        

      }

      dataSendServer = {
        'statusChatEndId': statusChatEndId,
        'schedulingDate': startDate + ' ' + formatedTimeHour(new Date(`${startDate} ${timerPicker.getHours()}:${timerPicker.getMinutes()}`)) + ':00',
        'schedulingTime': `${dateSendMessage} ${timeBefore}:00`,
        'message': textArea1
      }


    }


    props.func(dataSendServer)

    setOpen(false);
  };





  useEffect(() => {

    const hoursBeforeAvalible = (timer) => {

      let hours = []
      let hour = 1

     
     
     
     

      if (typeof (startDate) === 'string' && startDate.trim().length > 0 && startDate === dateCurrentFormated()) {

        

        while (subHours(timer, hour).getHours() >= 6 &&

          subHours(timer, hour).getHours() >= new Date().getHours() &&
          subHours(timer, hour).getHours() <= 20) {

          

          hours.push({ value: formatedTimeHour(subHours(timer, hour)), label: `${hour} HORA ANTES DO HORÁRIO DO AGENDAMENTO` })

          hour++;
        }

        if (hours.length > 1) {
         
          hours.pop()
          setCurrency(hours[0].value)
        }
        else {
          setCurrency(null)
        }

      }
      else {

        while (subHours(timer, hour).getHours() >= 6 && subHours(timer, hour).getHours() <= 20) {

         

          hours.push(
            {
              value: formatedTimeHour(subHours(timer, hour)),
              label: `${hour} HORA ANTES DO HORÁRIO DO AGENDAMENTO`
            })

          hour++;
        }

        if (hours.length > 0) {
         
          setCurrency(hours[0].value)
        }
        else {
          setCurrency(null)
        }

      }


      if (new Date(startDate) > addDays(new Date(), 1)) {

        hours.push({ value: formatedTimeHour(timerPicker), label: `24 HORAS ANTES DO HORÁRIO DO AGENDAMENTO` })

        

      }

     


      return { time: hours, hour: hour }

    }

   



    setCurrenciesTimeBefore(hoursBeforeAvalible(timerPicker).time)

  }, [timerPicker, startDate])





  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  // Get from child 1
  const textFieldSelect = (data) => {
   
    setStatusChatEnd(data)

  }


  const handleChange = (event) => {

    setTextArea1(event.target.value);

  };

  const handleCheckBoxChange = (event) => {

   
    setChecked(event.target.checked);
  };



  const handleChangeHourBefore = (event) => {

    

    // var matchedTime = currenciesTimeBefore.filter(i => i.label.indexOf('24 HORAS ANTES DO HORÁRIO DO AGENDAMENTO') >= 0);

   

    setCurrency(event.target.value);

  };



  return (

    <Dialog
      open={open}
      onClose={handleCancel}
      // fullWidth={true}
      // maxWidth={true}    
      disableEscapeKeyDown

      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >

      <DialogTitle id="scroll-dialog-title">{props.modal_header}</DialogTitle>
      <DialogContent dividers={scroll === 'body'}>

        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >

        </DialogContentText>


        <Box
          sx={{
            width: 500,
            height: '100%',
            // backgroundColor: 'primary.dark',
            // '&:hover': {backgroundColor: 'primary.main', opacity: [0.9, 0.8, 0.7],},
          }}>

          <Box sx={{
            display: 'grid',
          }}>
            <Item>
              <span>Selecione uma opção para encerrar o Atendimento</span>

              <SelectField func={textFieldSelect}
                emptyField={false}
                textBoxFieldSelected={'1'}
                header={'Opções de encerramento do atendimento'}
                currencies={props.statusChatEnd.map((obj) => {
                  return { 'value': obj.id, 'label': obj.name }
                })} />


            </Item>

          </Box>

          {statusChatEndId === '2' &&

            <Item>

              <span>Lembrete</span>

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>

                <Item><DatePicker func={datePickerValue} minDate={true} startEmpty={true} title={'Data'} /></Item>

                <Item><TimerPickerSelect func={timerPickerValue} title={'Hora'} /></Item>

              </Box>


              <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                <Item>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    value={textArea1}
                    placeholder={'Mensagem de envio para cliente'}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                  />
                </Item>
              </Box>

            </Item>
          }

          {statusChatEndId === '3' &&


            <Item>

              <span>Agendamento</span>

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>

                <Item><DatePicker func={datePickerValue} minDate={true} startEmpty={true} title={'Data'} /></Item>

                <Item><TimerPickerSelect func={timerPickerValue} title={'Hora'} /></Item>

              </Box>


              <Box sx={{ display: 'flex', flexDirection: 'column' }}>


                <Item>
                  {currencyHourBefore && startDate && typeof (startDate) === 'string' && startDate.trim().length > 0 && currenciesTimeBefore.length > 0 &&
                    <TextFieldSelectHourBefore
                      id="outlined-select-currency"
                      disabled={startDate.length > 0 ? false : true}
                      select
                      label="Enviar mensagem para o cliente"
                      value={currencyHourBefore}
                      size="small"
                      onChange={handleChangeHourBefore}
                    >
                      {currenciesTimeBefore.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextFieldSelectHourBefore>
                  }
                </Item>



                <Item>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    value={textArea1}
                    placeholder={'Mensagem de envio para o cliente'}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                  />
                </Item>

              </Box>

            </Item>

          }

          {schedulesContact.length > 0 &&
            <div></div>
          }


        </Box>
      </DialogContent>


      <DialogActions>
        <div>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleCheckBoxChange} />}
            label="Mensagem de encerramento de atendimento"
          />
        </div>
        <div style={{ marginRight: '50px' }}>
          <Button onClick={handleCancel}>Cancelar</Button>
        </div>
        <Button onClick={handleChatEnd}>Ok</Button>
      </DialogActions>
    </Dialog>

  );
}

export default Modal