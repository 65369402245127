import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useTickets = ({
	searchParam,
	searchParamContent,
	pageNumber,
	status,
	date,
	showAll,
	queueIds,
	withUnreadMessages,
	unlimited,
	tab
}) => {
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [tickets, setTickets] = useState([]);




	useEffect(() => {

		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTickets = async () => {
				try {

					if ((tab === 'search') && ( !searchParam  || searchParam.trim().length === 0 || searchParam.trim().length >40 || searchParam.endsWith(' '))) { 
						return
					}

					const { data } = await api.get("/tickets", {
						params: {
							searchParam,
							searchParamContent,
							pageNumber,
							status,
							date,
							showAll,
							queueIds,
							withUnreadMessages,
							unlimited
						},
					});




					setTickets(data.tickets);
					setHasMore(data.hasMore);
					setLoading(false);


				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchTickets();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [
		searchParam,
		searchParamContent,
		pageNumber,
		status,
		date,
		showAll,
		queueIds,
		withUnreadMessages,
		tab,
		unlimited
	]);

	return { tickets, loading, hasMore };
};

export default useTickets;
