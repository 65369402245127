import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import Modal from "../ChatEnd/ModalChatEnd";
import { render } from '@testing-library/react';

import { TabTicketContext } from "../../context/TabTicketHeaderOption/TabTicketHeaderOption";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket, statusChatEnd }) => {   
	const classes = useStyles();
	const history = useHistory();  

	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	
	// const [useDialogflow, setUseDialogflow] = useState(ticket.contact.useDialogflow);

	// const [/*useDialogflow*/, setUseDialogflow] = useState(() => {
	// 	if (Object.keys(ticket).length !== 0) {
	// 	  return ticket.contact.useDialogflow;
	// 	} else {
	// 	  // Set a default value if `ticket.contact.useDialogflow` is null
	// 	  return true
	// 	}
	//   });

	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const { tabOption, setTabOption } = useContext(TabTicketContext);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};


	const chatEndVal = (data) => {

		if (data) {

			data = { ...data, 'ticketId': ticket.id } 

			handleUpdateTicketStatus(null, "closed", user?.id, data)

		}

	}


	const handleModal = (/*status, userId*/) => {

		render(<Modal
			modal_header={'Finalização de Atendimento'}
			func={chatEndVal}
			statusChatEnd={statusChatEnd}
			ticketId={ticket.id}
		/>)

	};


	const handleUpdateTicketStatus = async (e, status, userId, schedulingData = {}) => {

		setLoading(true);
		try {

			if (status === 'closed') {

				if (tabOption === 'search') {
					setTabOption('open')
				}

				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					userId: userId || null,
					schedulingNotifyData: JSON.stringify(schedulingData)
				});

			}
			else {

				if (tabOption === 'search') {
					setTabOption('open')
				}

				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					userId: userId || null
				});

			}

			setLoading(false);
			if (status === "open") {


				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}

	};

	// const handleContactToggleUseDialogflow = async () => {
	// 	setLoading(true);
	// 	try {
	// 		const contact = await api.put(`/contacts/toggleUseDialogflow/${ticket.contact.id}`);
	// 		setUseDialogflow(contact.data.useDialogflow);
	// 		setLoading(false);
	// 	} catch (err) {
	// 		setLoading(false);
	// 		toastError(err);
	// 	}
	// };


	return (
		<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<ButtonWithSpinner style={{ marginRight: "70px" }}
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>

					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => {


							handleModal()
							// handleUpdateTicketStatus(e, "closed", user?.id)

						}}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner>

					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtons;
