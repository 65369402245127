import React, { useState, useEffect, useContext } from 'react'
import openSocket from 'socket.io-client'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select'
import { toast } from 'react-toastify'

import api from '../../services/api'
import { i18n } from '../../translate/i18n.js'
import toastError from '../../errors/toastError'

//--------
import { AuthContext } from '../../context/Auth/AuthContext'

import { Can } from '../../components/Can'

// import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },

  settingOption: {
    marginLeft: 'auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

const Settings = () => {
  const classes = useStyles()

  //--------
  const { user } = useContext(AuthContext)

  const [settings, setSettings] = useState([])

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get('/settings')
        setSettings(data.settings)
      } catch (err) {
        toastError(err)
      }
    }
    fetchSession()
  }, [])

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    socket.on('settings', (data) => {
      console.log('settings updated ----------------------------')

      if (data.action === 'update') {
        setSettings((prevState) => {
          const aux = [...prevState]
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key)
          aux[settingIndex].value = data.setting.value
          return aux
        })
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    console.log('------> settings: ', settings)
  }, [settings])

  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value
    const settingKey = e.target.name

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
      })
      toast.success(i18n.t('settings.success'))
    } catch (err) {
      toastError(err)
    }
  }

  const getSettingValue = (key) => {
    const { value } = settings.find((s) => s.key === key)

    return value
  }

  return (
    <Can
      role={user.profile}
      perform="settings-view:show"
      yes={() => (
        <div>
          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Typography variant="body2" gutterBottom>
                {i18n.t('settings.title')}
              </Typography>

              <Paper className={classes.paper}>
                <Typography variant="body1">
                  {i18n.t('settings.settings.userCreation.name')}
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="userCreation-setting"
                  name="userCreation"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('userCreation')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">
                    {i18n.t('settings.settings.userCreation.options.enabled')}
                  </option>
                  <option value="disabled">
                    {i18n.t('settings.settings.userCreation.options.disabled')}
                  </option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">Editar ura</Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="editURA-setting"
                  name="editURA"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('editURA')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">Editar fila</Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="editQueue-setting"
                  name="editQueue"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('editQueue')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Contato conversar com whatsapps distintos no omnihit
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="oneContactChatWithManyWhats-setting"
                  name="oneContactChatWithManyWhats"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('oneContactChatWithManyWhats')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>


          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Whatsapp Cloud API
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="whatsaAppCloudApi-setting"
                  name="whatsaAppCloudApi"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('whatsaAppCloudApi')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>


          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Escopo de transferência de usuario por filas atribuidas a conexão e usuarios atribuido a filas
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="queueTransferByWhatsappScope-setting"
                  name="queueTransferByWhatsappScope"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('queueTransferByWhatsappScope')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Modulo campanha
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="hasCampaign-setting"
                  name="hasCampaign"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('hasCampaign')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

        </div>
      )}
    />
  )
}

export default Settings
