 
import React, { useState, useEffect, useRef, useReducer } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';  
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  
import PropTypes from 'prop-types';
import Box from '@mui/material/Box'; 
import SelectField from "../Report/SelectField";  
  
import DatePicker from './DatePicker2'  
import TimerPickerSelect from './TimerPickerSelect2'   
import TextareaAutosize from '@mui/material/TextareaAutosize';  

import { subHours, addDays, subDays} from "date-fns";  
import TextFieldSelectHourBefore from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'; 
 
 

  
 import api from "../../services/api"; 
import toastError from "../../errors/toastError"; 

 

const reducer = (state, action) => { 
 

  if (action.type === "LOAD_SCHEDULES") {
    const schedulesContact = action.payload;
    const newSchedules= [];

    schedulesContact.forEach((schedule) => {
      const scheduleIndex = state.findIndex((s) => s.id === schedule.id);
      if (scheduleIndex !== -1) {
        state[scheduleIndex] = schedule;
      } else {
        newSchedules.push(schedule);
      }
    });

    return [...state, ...newSchedules];
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;
    const scheduleIndex = state.findIndex((q) => q.id === scheduleId);
    if (scheduleIndex !== -1) {
      state.splice(scheduleIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

 

const Item = (props) => {
 
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
}; 
 
 

const Modal = (props) => { 
 
  // const [selectedSchedule, setSelectedSchedule] = useState(null);
  // const [confirmModalOpen, setConfirmModalOpen] = useState(false); 
   
  const [open, setOpen] = useState(true);
  const [scroll, /*setScroll*/] = useState('body');
  const [statusChatEndId, setStatusChatEnd] = useState(props.rowData.statusChatEndId)  
  const [startDate, setDatePicker] = useState(props.rowData.schedulingTime ? new Date(props.rowData.schedulingTime) :  new Date())  
  const [timerPicker, setTimerPicker] = useState(new Date()) 
  const [textArea1, setTextArea1] = useState(props.rowData.message)  
  const [schedulesContact, dispatch] =  useReducer(reducer, []); 
 
  const [currencyHourBefore, setCurrency] = useState(props.rowData.schedulingTime.split(' ')[1].slice(0,5));
  const [currenciesTimeBefore, setCurrenciesTimeBefore] = useState(null);  

  const [currencyHourBeforeAux, ] = useState(props.rowData.schedulingTime.split(' ')[1].slice(0,5));

  if(schedulesContact){} 
  
 
  const handleCancel = (event, reason) => {

    if (reason && reason === "backdropClick") 
        return;
 
    setOpen(false);
  };

 

  useEffect(() => { 
    
    (async () => { 
      try {       

        const { data } = await api.get("/tickets/" + props.rowData.ticketId); 
        
        dispatch({ type: "LOAD_SCHEDULES", payload: data.schedulesContact });
 
      } catch (err) {
        toastError(err); 
      }
    })();
  }, [props]); 
  
 
  function formatedTimeHour(timer){
    return  `${timer.getHours().toString().padStart(2, '0')}:${timer.getMinutes().toString().padStart(2, '0')}`
  }

  function formatedFullCurrentDate(){
    let dateCurrent = new Date() 
    let day = dateCurrent.getDate().toString().padStart(2, '0');
    let month = (dateCurrent.getMonth()+1).toString().padStart(2, '0');
    let year = dateCurrent.getFullYear();     
    return `${year}-${month}-${day}`; 
  }
 
   

  // const handleCloseConfirmationModal = () => {
  //   setConfirmModalOpen(false);
  //   setSelectedSchedule(null);
  // };
  

  // const handleDeleteSchedule = async (scheduleId) => {
  //   try {
  //     await api.delete(`/schedule/${scheduleId}`);
  //     toast.success(("Lembrete/Agendamento deletado com sucesso!")); 
  //     dispatch({ type: "DELETE_SCHEDULE", payload: scheduleId }); 
  //   } catch (err) {
  //     toastError(err);
  //   }
  //   setSelectedSchedule(null);
  // };

  // Get from child 2
const datePickerValue = (data) => { 
  
  setDatePicker(data) 

   
}

// Get from child 3
const timerPickerValue = (data) => { 
  
  setTimerPicker(data)

 
}



const dateCurrentFormated = (dateF=null) => { 

  let date =null

  if(dateF){
    date = new Date(dateF)
  }
  else{
    date = new Date();
  }

  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth()+1).toString().padStart(2, '0');
  let year = date.getFullYear();   
   
  return `${year}-${month}-${day}`

}

 

  const handleChatEnd = (event, reason) => {   
    
    let dataSendServer = {'statusChatEndId': statusChatEndId}

    if (reason && reason === "backdropClick") 
        return;

    if (statusChatEndId === '2'|| statusChatEndId === '3'){ 

      
      

      if(textArea1 && textArea1.trim().length<5){
        alert('Mensagem muito curta!')
        return
      }
      else if(!textArea1){
        alert('Defina uma mensagem para enviar para o cliente!')
        return
      }
      else if(formatedFullCurrentDate()===startDate &&        
      ((new Date(timerPicker).getHours() < new Date().getHours() && new Date(timerPicker).getMinutes() <= new Date().getMinutes()) ||
      (new Date(timerPicker).getHours() === new Date().getHours() && new Date(timerPicker).getMinutes() <= new Date().getMinutes()) || 
      (new Date(timerPicker).getHours() < new Date().getHours() && new Date(timerPicker).getMinutes() >= new Date().getMinutes()) ||
      (new Date(timerPicker).getHours() < new Date().getHours))){
          alert('Horário menor ou igual horário atual!') 
          return 
    }
    else if((new Date(timerPicker).getHours() > 20 && new Date(timerPicker).getMinutes() > 0) ||
    (new Date(timerPicker).getHours() < 6)){
      alert('Horário comercial inválido!\n Selecione um horário de lembrete válido entre às 06:00 e 20:00')
      return
    } 
 


      let dateSendMessage = startDate
      let timeBefore = formatedTimeHour(new Date(`${startDate} ${timerPicker.getHours()}:${timerPicker.getMinutes()}:00`))

      if(statusChatEndId === '3'){

        if(!currencyHourBefore){
          alert('Para agendamentos do dia corrente, essa funcionalidade atende a agendeamentos com no mínimo 2 horas adiantado a partir da hora atual!')
          return    
        } 

        timeBefore = currencyHourBefore

        let sendMessageDayBefore = currenciesTimeBefore.filter(i => i.label.indexOf('24 HORAS ANTES DO HORÁRIO DO AGENDAMENTO') >= 0);

        if(sendMessageDayBefore.length > 0 && timeBefore === formatedTimeHour(timerPicker))
        {
          
          
         
          dateSendMessage = dateCurrentFormated( new Date(subDays(new Date(startDate+' '+formatedTimeHour(new Date(`${startDate} ${timerPicker.getHours()}:${timerPicker.getMinutes()}:00`))), 1)))
        }  
 

        

      } else if (statusChatEndId === '2'){  
        
 
      }

      dataSendServer = { 
        'schedulingNotifyId':  props.rowData.id,   
        'statusChatEndId': statusChatEndId,
        'schedulingDate':  startDate+' '+formatedTimeHour(new Date(`${startDate} ${timerPicker.getHours()}:${timerPicker.getMinutes()}`))+':00',  
        'schedulingTime': `${dateSendMessage} ${timeBefore}:00`, 
        'message': textArea1  
      }
 

    
      const formatDateTimeBrazil = (dateTime) => { 
        let date = dateTime.split(' ')[0]
        let time = dateTime.split(' ')[1] 
        date = date.split('-') 
        return `${date[2]}/${date[1]}/${date[0]} ${time}` 
      }

    
    props.rowData['statusChatEndId'] =  statusChatEndId
    props.rowData['schedulingDate'] = formatDateTimeBrazil(`${dataSendServer['schedulingDate']}`)
    props.rowData['schedulingTime'] = formatDateTimeBrazil(`${dataSendServer['schedulingTime']}`)
    props.rowData['message'] = textArea1 

    } 
  
    props.func(dataSendServer, props.rowData)  

    setOpen(false);
  };




  useEffect(()=>{
      
    const selectedTimeOld = (_hours) => {

      let selectedOld = _hours.filter((h)=>(h.value===currencyHourBeforeAux))[0]

      if(selectedOld){
        
        return selectedOld
      }

      return null

    }

    const hoursBeforeAvalible = (timer) =>{  
  
      let hours = []
      let hour = 1        
       

        if(typeof(startDate)==='string' && startDate.trim().length>0 && startDate === dateCurrentFormated()){
  
     
  
          while(subHours(timer, hour).getHours()>=6 && 

          subHours(timer, hour).getHours()>=new Date().getHours() && 
          subHours(timer, hour).getHours()<=20){  
              
            hours.push({value: formatedTimeHour(subHours(timer,hour)), label: `${hour} HORA ANTES DO HORÁRIO DO AGENDAMENTO`})
  
            hour++;
          }
  
          if(hours.length>1){  
            hours.pop()    

            let selectedOld = selectedTimeOld(hours)
            setCurrency(selectedOld ? selectedOld.value : hours[0].value)  
  
          }
          else{
            setCurrency(null)
          } 
  
        }
        else{ 
  
          while(subHours(timer, hour).getHours()>=6 && subHours(timer, hour).getHours()<=20){ 
  
            hours.push(
              {value: formatedTimeHour(subHours(timer,hour)), 
              label: `${hour} HORA ANTES DO HORÁRIO DO AGENDAMENTO`})
  
            hour++;
          }
  
          if(hours.length>0){   
 

            let selectedOld = selectedTimeOld(hours)
            setCurrency(selectedOld ? selectedOld.value : hours[0].value)  

             //if(contador>1)
              // setCurrency( hours[0].value)  
          }
          else{
            setCurrency(null)
          } 
  
        }


        if(new Date(startDate) > addDays(new Date(), 1) ){ 

          hours.push({value: formatedTimeHour(timerPicker) , label: `24 HORAS ANTES DO HORÁRIO DO AGENDAMENTO`}) 
          
          let selectedOld = selectedTimeOld(hours)

          if(selectedOld)
          setCurrency(selectedOld.value)  
        } 
    
        return {time: hours, hour:hour}       
  
    }   
      
    setCurrenciesTimeBefore(hoursBeforeAvalible(timerPicker).time)   
       
  },[timerPicker, startDate, currencyHourBeforeAux])


  


  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  // Get from child 1
const textFieldSelect = (data) => {
  
  setStatusChatEnd(data)
}

 
  

const handleChange = (event) => {
    
  setTextArea1(event.target.value);

};


const handleChangeHourBefore = (event) => {   

  setCurrency(event.target.value); 
 

};
 


  return (  
 
    <Dialog 
            open={open}
            onClose={handleCancel} 
            // fullWidth={true}
            // maxWidth={true}    
            disableEscapeKeyDown

            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >   

            <DialogTitle id="scroll-dialog-title">{props.modal_header}</DialogTitle>
            <DialogContent dividers={scroll === 'body'}>

              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                
              </DialogContentText> 

                  
                <Box
                      sx={{
                        width: 500,
                        height: '100%',
                        // backgroundColor: 'primary.dark',
                        // '&:hover': {backgroundColor: 'primary.main', opacity: [0.9, 0.8, 0.7],},
                      }}> 

                          <Box  sx={{
                              display: 'grid',  
                            }}>
                            <Item>
                                
                                <SelectField func={textFieldSelect} 
                                emptyField={false} 
                                textBoxFieldSelected={`${props.rowData.statusChatEndId}`}
                                header={'Opções lembrete/agendamento'} 
                                currencies={props.statusChatEnd.map((obj)=>{
                                          return {'value': obj.id, 'label': obj.name}
                                        })}/>
                                

                            </Item> 

                          </Box> 
                          
                          {statusChatEndId==='2' &&
                          
                          <Item> 

                            <span>Lembrete</span>

                          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> 
                            
                            <Item>
                              <DatePicker 
                              func={datePickerValue} 
                              minDate={true}  
                              schedulingDate={props.rowData.schedulingDate} 
                              title={'Data'}/>
                            </Item>
                            
                            <Item>
                              <TimerPickerSelect 
                              func={timerPickerValue} 
                              schedulingDate={props.rowData.schedulingDate} 
                              title={'Hora'}/>  
                            </Item>
                              
                          </Box>


                          <Box sx={{display: 'flex', flexDirection: 'column' }}>     
                            
                            <Item>
                              <TextareaAutosize 
                                  aria-label="minimum height"
                                  minRows={3}  
                                  value={textArea1}
                                  placeholder={'Mensagem de envio para cliente'}   
                                  onChange={ handleChange}  
                                  style={{ width: '100%' }}
                              />
                            </Item>
                          </Box>

                        </Item>
                        }


                        {statusChatEndId==='3' &&  
                            

                           <Item> 

                            <span>Agendamento</span>

                          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}> 
                            
                            <Item>
                                <DatePicker 
                                func={datePickerValue} 
                                minDate={true}  
                                schedulingDate={props.rowData.schedulingDate} 
                                title={'Data'}/>
                              </Item>
                              
                              <Item>
                                <TimerPickerSelect 
                                func={timerPickerValue} 
                                schedulingDate={props.rowData.schedulingDate} 
                                title={'Hora'}/>  
                              </Item>
                              
                          </Box>


                          <Box sx={{display: 'flex', flexDirection: 'column' }}>    

                           
                            <Item>              
                              {currencyHourBefore && startDate && typeof(startDate)==='string' && startDate.trim().length > 0 && currenciesTimeBefore.length > 0 && 
                              <TextFieldSelectHourBefore
                                id="outlined-select-currency"
                                disabled={startDate.length>0 ? false : true}
                                select
                                label="Enviar mensagem para cliente"
                                value={currencyHourBefore}
                                size="small"
                                onChange={handleChangeHourBefore} 
                                >
                                {currenciesTimeBefore.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextFieldSelectHourBefore> 
                              }
                            </Item>


                            
                            <Item>
                            <TextareaAutosize 
                                  aria-label="minimum height"
                                  minRows={3}  
                                  value={textArea1}
                                  placeholder={'Mensagem de envio para cliente'}   
                                  onChange={ handleChange}  
                                  style={{ width: '100%' }}
                              />
                            </Item>

                          </Box>

                        </Item>
                          
                        } 

                 
                                   
                                        
                                </Box>   
                            </DialogContent>
            
    
            <DialogActions>     
              <div style={{marginRight:'50px'}}>
              <Button onClick={handleCancel}>Cancelar</Button>  
              </div>
              <Button onClick={handleChatEnd}>Ok</Button>  
            </DialogActions>
          </Dialog>  

  );
}

export default Modal